/* istanbul ignore file */
import { configureLocalization } from "@lit/localize"

const targetLocales = ["de"]

let { getLocale, setLocale } = configureLocalization({
  sourceLocale: "en",
  targetLocales,
  loadLocale: (locale) => import(`../locale/${locale}.js`),
})

const orgSetLocale = setLocale

setLocale = (locale) => {
  if (targetLocales.includes(locale)) {
    orgSetLocale(locale)
  }
}

export { getLocale, setLocale }
